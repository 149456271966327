import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import RevealController from 'stimulus-reveal'
import Lightbox from "stimulus-lightbox"
import "lightgallery.js/dist/css/lightgallery.min.css"

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)

application.load(definitionsFromContext(context))
application.register("lightbox", Lightbox)
application.register('reveal', RevealController)