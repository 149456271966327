import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'nextButton', 'prevButton']

  connect() {
    console.log('connected')
    new Swiper(this.containerTarget, {
      loop: true,
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 10,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,
      },
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
      breakpoints: {
        1024: {
          allowTouchMove: false,
        },
      },
    })
  }
}
