//= require select2

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import Turbolinks from 'turbolinks'
import 'controllers'
import 'tailwindcss/tailwind.css'
import 'alpine-turbo-drive-adapter'
import 'alpinejs'


require.context('../images', true)
import '../css/application.scss'

Rails.start()
ActiveStorage.start()
Turbolinks.start()