import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['imageContainer', 'nextButton', 'prevButton', 'contentContainer']

  connect() {
    const imageSlider = new Swiper(this.imageContainerTarget, {
      loop: true,
      speed: 450,
      effect: 'fade',

      slidesPerView: 1,
    })

    const contentSlider = new Swiper(this.contentContainerTarget, {
      loop: true,
      speed: 450,
      slidesPerView: 1,
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
    })

    imageSlider.controller.control = contentSlider
    contentSlider.controller.control = imageSlider
  }
}
