import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['swatchContainer', 'previewImageContainer']

  connect() {
    const swatchCount = this.swatchContainerTarget.getAttribute('data-swatchCount')
    const swatchStart = Math.round(swatchCount / 2) - 1
    const selectionSlider = new Swiper(this.swatchContainerTarget, {
      speed: 450,
      initialSlide: swatchStart,
      slidesPerView: 1.4,
      spaceBetween: 20,
      centeredSlides: true,
      slideToClickedSlide: true,

      breakpoints: {
        468: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },

        1030: {
          slidesPerView: 4,
        },

        1280: {
          slidesPerView: 5,
        },
      },
    })

    const previewSlider = new Swiper(this.previewImageContainerTarget, {
      speed: 450,
      slidesPerView: 1,
      initialSlide: swatchStart,
      slideToClickedSlide: true,
      effect: 'fade',
      fadeEffect: {
        crossfade: true,
      },
      allowTouchMove: false,
    })

    previewSlider.controller.control = selectionSlider
    selectionSlider.controller.control = previewSlider
  }
}
