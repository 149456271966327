import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['navBar', 'mainNav', 'burgerButton', 'container', 'logo', 'closeButton', 'navLogo']

  connect() {}

  toggleNav() {
    this.burgerButtonTarget.classList.toggle('hidden')
    this.containerTarget.classList.toggle('active')
    this.navBarTarget.classList.toggle('active')
    this.logoTarget.classList.toggle('text-white')
    this.mainNavTarget.classList.toggle('hidden')
    this.mainNavTarget.classList.toggle('pointer-events-auto')

    this.closeButtonTarget.classList.toggle('hidden')

    this.logoTarget.classList.toggle('hidden')
    this.navLogoTarget.classList.toggle('hidden')
  }
}
